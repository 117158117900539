<template>
    <div  class="index_page">
    <div class="banner">
			<swiper class="swiper-no-swiping" ref="swiperTop" :options="swiperOptionTop" @slideChangeTransitionStart="slideChangeTransitionStart" @slideChangeTransitionEnd="slideChangeTransitionEnd">
				<swiper-slide class="" v-for="(item,index) in bannerlist" :key="index">
					<img :src="item.posterUrl">
				</swiper-slide>
			</swiper>
      <!-- <div class="swiper_pagination">
        <div v-for="(item,index) in bannerlist" :key="index" :class="{active_swiper:swiperTopIndex === index}" @click.stop="chosSwiperIndex(index)">
          <div :style="{animationPlayState: animationPlayState ? '' : 'paused'}"></div>
          <div></div>
        </div>
      </div> -->
      <div class="swiper_pagination">
        <div v-for="(item,index) in bannerlist" :key="index" @click.stop="chosSwiperIndex(index)">
          <div :style="{width: swiperTopIndex === index ? sum + '%' : ''}"></div>
          <div></div>
        </div>
      </div>
		</div>
    <div class="width100">
      <img class="twobgimg" :src="images[mcSwiperIndex].posterUrl"/>
      <div class="enterprise-div">
        <div class="flex towtitle">
          <img src="@/assets/img/index/icon1.png"/>
          企业服务
          <img class="rimg" src="@/assets/img/index/icon1.png"/>
        </div>
        <div class="flex" style="position:relative;">
          <div @click="slidePrev" class="pagel">
            <img src="@/assets/img/index/icon2.png"/>
          </div>
         
            <swiper ref="mcSwiper" class="echw-fb-swiper" :options="swiperOption" @slideChangeTransitionEnd="slideChangeTransitionEndTo">
              <swiper-slide class="echw-fb-swiper-slide" v-for="(item, index) in images"  :key="index" >
                <div class="enterprise_card" :class="{ mc_swiper_active: mcSwiperIndex === index }">
                  <div class="blue-top"></div>
                  <div class="blue-bottom"></div>
                  <div class="title">{{item.title}}</div>
                  <div class="content">{{item.content}}</div>
                  <img class="dian" src="@/assets/img/index/icon3.png"/>
                  <img :src="item.posterUrl" class="swipimg">
                </div>
              </swiper-slide>
            </swiper>
          <div @click="slideNext" class="pager">
            <img src="@/assets/img/index/icon2.png"/>
          </div>
        </div>
      </div>
    </div>
    <div class="width100 width102">
      <div class="tabthree flex">
        <div class="tabflex1" :class="{tabactive:tabIndex === index}" v-for="(item, index) in tabList" :key="index" @click="tabIndex = index">
          <img :src="tabIndex === index ? item.icon : item.iconSele"/>
          <span>{{item.title}}</span>
        </div>
      </div>
      <div class="divthree flex">
        <div class="threel">
          <div class="threeh2">{{tabList[tabIndex].title}}</div>
          <div class="threeh3">
            {{tabList[tabIndex].tips}}
          </div>
          <div class="thul flex">
            <div class="thli flex" v-for="(item,index) in tabList[tabIndex].threelist1" :key="index">
              <img src="@/assets/img/index/icon8.png" />{{item.text}}
            </div>
          </div>
          <div class="border-b"></div>
          <div class="morebtn" @click="goPage">了解更多</div>
        </div>
        <div class="flex1"></div>
        <img class="threer" :src="tabIndex === 0 ? 'https://qxqy-online.sdhckjvip.com/official/Home_Agent_Icon%402x.png' : 'https://qxqy-online.sdhckjvip.com/OfficialWebsite/image17v.png'"/>
      </div>
    </div>
		<div class="width100 width103">
      <div class="flex towtitle">
        <img src="@/assets/img/index/icon1.png"/>
        技术开发服务
        <img class="rimg" src="@/assets/img/index/icon1.png"/>
      </div>
      <div class="scroll_box">
        <div class="bg_img"></div>
        <div class="bg_img_to"></div>
      </div>
    </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'Index',
    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
          activeIndex:2,
          tabList: [
            {
              id: 1,
              title: '代理商服务',
              tips: '完善的代理体系和服务支持，0成本无套路，推广无忧',
              icon: require("../../assets/img/index/icon5.png"),
              iconSele: require("../../assets/img/index/icon4.png"),
              threelist1:[
                {
                  text:'平台大力扶持'
                },
                {
                  text:'客服1V1服务'
                },
                {
                  text:'免费培训学习'
                },
                {
                  text:'零成本代理运营'
                }
              ],
            },
            {
              id: 2,
              title: '分销商服务',
              tips: '便捷的分销模式，无需囤货无需压款，自定义利润赚钱随心所欲',
              icon: require("../../assets/img/index/icon7.png"),
              iconSele: require("../../assets/img/index/icon6.png"),
              threelist1:[
                {
                  text:'零成本分销模式'
                },
                {
                  text:'客服1V1服务'
                },
                {
                  text:'自定义商品利润'
                },
                {
                  text:'自销自赚不囤货'
                }
              ],
            }
          ],
          tabIndex: 0,
          bannerlist:[
            {
              posterUrl:'https://qxqy-online.sdhckjvip.com/official/banner/banner_12x.png',
              path:'/'
            },
            {
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/banner/banner_22x.png',
            path:'/enterprise'
            },
            {
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/banner/banner_32x.png',
            path:'/partner'
            },
            {
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/banner/banner_42x.png',
            path:'/technology'
            },
            // {
            // posterUrl:'https://qxqy-online.sdhckjvip.com/official/banner/banner_52x.png',
            // path:'/aboutUs'
            // }
          ],
          images:[
            {
            title:'会员营销一体化发行服务',
            content:'客群场景搭建，打通线上营销最后一公里，创造新的收入增长点',
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/index/enterprise4.png'
            }
            ,
            {
            title:'数字化福利解决方案',
            content:'助力企业打造“幸福职场”，降低采购成本，扩大员工福利选择自由度',
              posterUrl:'https://qxqy-online.sdhckjvip.com/official/index/enterprise1.png'
            },
            {
            title:'数字化营销解决方案',
            content:'全场景闭环营销链路，拉新、促活、留存、转化利器',
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/index/enterprise2.png'
            },
            {
            title:'服务开发平台',
            content:'稳定、快捷、安全的综合服务开发平台',
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/index/enterprise3.png'
            }
            ,
            
            {
            title:'权益商品行业解决方案',
            content:'优势的行业价格，稳定的技术平台，丰富的权益产品，贴心的运营服务',
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/index/enterprise5.png'
            }
          ],
          bottomlist1:[
             {
            posterUrl:'https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_1.png'
            },
            //  {
            // posterUrl:'https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_2.png'
           
            // }
          ],
          bottomlist2:[
             {
                        posterUrl:'https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_3.png'

            },
             {
                        posterUrl:'https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_4.png'

            }
          ],
          swiperOption: {
                          spaceBetween: 30,
                          slidesPerView: 3, // 一屏显示的slide个数
                          centeredSlides: true,// 居中的slide是否标记为active，默认是最左active,这样样式即可生效
                          slideToClickedSlide: true,// 点击的slide会居中
                          loop: true,// 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
                          on: {
                              // 该方法中的this都指代swiper本身
                              tap: function () {
                                  console.log('点击的位置', this.activeIndex);
                              },
                             
                          },
                      },
          swiperOptions: {
            loop: true,
            speed: 6000,
            autoplay: {
              delay: 0,
            },
          },
          swiperOptionsTo: {
            loop: true,
            speed: 6000,
            autoplay: {
              delay: 0,
            },
          },
          swiperOptionTop: {
            loop: true,
            speed: 1000,
            autoplay : {
              delay: 3000,
              disableOnInteraction: false
            },
          },
          swiperTopIndex: 0,
          mcSwiperIndex: 0,
          animationPlayState: true,
          sum: 0
        };
    },
    
   computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // },
  },
    watch: {
  
    },
    mounted() {
      this.getWidtnSum()
    // let this_swiper = this.$refs.mySwiper.$swiper
    // this_swiper.$wrapperEl[0].style['transition-timing-function'] = 'linear'
    // let this_swiperTo = this.$refs.mySwiperTo.$swiper
    // this_swiperTo.$wrapperEl[0].style['transition-timing-function'] = 'linear'
    this.$refs.swiperTop.$swiper.el.onmouseover = () => {
      clearInterval(this.interval)
      this.$refs.swiperTop.$swiper.autoplay.stop();
      this.animationPlayState = false
    }
    this.$refs.swiperTop.$swiper.el.onmouseout = () => {
      this.sum = 0
      this.getWidtnSum()
      this.$refs.swiperTop.$swiper.autoplay.start();
      this.animationPlayState = true
    }
  },
  methods: {
    
    getWidtnSum() {
      if(this.interval) {
        clearInterval(this.interval)
      }
      this.interval = setInterval(() => {
        this.sum++
        if(this.sum >= 100) {
          this.sum = 0
          clearInterval(this.interval)
        }
        this.$forceUpdate()
      }, 3000/100)
    },
    goPage() {
      if(this.tabIndex === 0) {
        this.$router.push('/partner')
      } else {
        this.$router.push('/aboutUs')
      }
    },
    slidePrev() {
      this.$refs.mcSwiper.$swiper.slidePrev();
    },
    slideNext() {
      this.$refs.mcSwiper.$swiper.slideNext();
    },
    chosSwiperIndex(index) {
      if(index === this.swiperTopIndex) return
      this.sum = 0
      this.getWidtnSum()
      this.$refs.swiperTop.$swiper.slideToLoop(index);
    },
    slideChangeTransitionEnd() {
      this.sum = 0
      this.getWidtnSum()
      this.swiperTopIndex = this.$refs.swiperTop.$swiper.realIndex
    },
    slideChangeTransitionEndTo() {
      this.mcSwiperIndex = this.$refs.mcSwiper.$swiper.realIndex
      console.log(this.mcSwiperIndex);
    },
    slideChangeTransitionStart() {
      clearInterval(this.interval)
    },
 
  }

};
</script>
<style lang='scss' scoped>
.index_page{
	.banner{
    position: relative;
		width: 100%;
		img{
			width: 100%;
		}
	}
}
.swiper {
    width: 100%;
    height: 50px;
    bottom: 10px;
    position: absolute;
    background-color: darkred;
}

.swiper-container {
    width: 100%;
    height: 100%;
}
.width100{
  width: 100%;
  overflow: auto;
  zoom: 1;
  background: url('https://qxqy-online.sdhckjvip.com/official/index/home_business_bg@2x.png') no-repeat;
  background-size: 100% 100%;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
  .twobgimg{
    height: 1800px;
    width: 2000px;
    opacity: 0.1;
    position: absolute;
    left: 660px;
    top: -520px;
  }
}
.towtitle{
  justify-content: center;
  align-items: center;
  font-size: 40px;
  padding-top: 126px;
  padding-bottom: 82px;
  font-weight: bold;
  color: #333333;
  line-height: 47px;
  img{
    width: 60px;
    height: 60px;
    margin-right: 40px;
  }
  .rimg{
    transform: rotateY(180deg);
    margin-left: 40px;
    margin-right: 0;
  }
}
.enterprise-div{
  width: 1400px;
  margin: 0 auto;
  .swiper-slide {
  
  border-radius: 16px;
  margin-right: 20px;
  .swiper-slide-prev{
    
  }
  
	.enterprise_card{
		width: 360px;
		height: 440px;
		padding: 0 40px;
		background: linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(246,249,255,0.85) 100%);
		border-radius: 16px;
    position: relative;
    overflow: hidden;
    .dian{
      width: 66px;
      height: 39px;
      position: absolute;
      bottom: 40px;
    }
    .blue-top{
      width: 64px;
      height: 6px;
      background: linear-gradient(270deg, #82D6FF 0%, #0055FF 100%);
      box-shadow: 0px 4px 4px -2px rgba(0,85,255,0.24);
      border-radius: 3px 3px 3px 3px;
      position: absolute;
      top: 40px;
      // left: px;
      transition: ease all 0.5s;
    }
    .blue-bottom{
      width: 0;
      height: 6px;
      background: linear-gradient(270deg, #82D6FF 0%, #0055FF 100%);
      box-shadow: 0px 4px 4px -2px rgba(0,85,255,0.24);
      border-radius: 3px 3px 3px 3px;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 110;
      transition: ease all 0.5s;
    }
    .title{
      font-size: 28px;
      font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
      font-weight: normal;
      color: #555659;
      line-height: 33px;
      padding-top: 78px;
      padding-bottom: 12px;
      
    }

    .content{
      font-size: 1.25rem;
      color: #555659;
      // line-height: 2rem;
    }
    .swipimg{
      width: 400px;
      height: 360px;
      position: absolute;
      right: -70px;
      bottom: -30px;
      opacity: 0.2;
    }
	}

    width: 440px;
		height: 440px;
    transition: 300ms;
    transform-origin:50% 50%;
    transform: scale(0.8);
    
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
    
    z-index: 200;
    transform: scale(1);

    
}
.swiper-slide-active .swipimg{
      opacity: 1 !important;
    }
  
}
.pagel,.pager{
    flex: none;
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(246,249,255,0.85) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
    position: relative;
    z-index: 200;
    img{
      width: 32px;
      height: 32px;
    }
  }
  .pager{
    transform: rotateY(180deg);
    margin-left: 30px;
  }
  .echw-fb-swiper{
    width: calc(100% - 100px);
  }

.width102{
  padding-top: 126px;
  background: url('https://qxqy-online.sdhckjvip.com/official/index/Home_Partners_Bg@2x.png') no-repeat;
}

.tabthree{
  width: 644px;
  height: 92px;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 16px;
  border:1px solid #DFE3EB;
  align-items: center;
  img{
    width: 52px;
    height: 52px;
    margin-left: -6px;
  }
  span{
    margin-top: -1px;
  }
  .tabflex1{
    flex: 1;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: 84px;
    line-height: 84px;
    font-weight: bold;
    color: #767F8C;
    border-radius: 14px;
    cursor: pointer;
  }
  .tabflex1:hover{
    color: #3273F6;
  }
  .tabactive{
    color: #ffffff;
    background: linear-gradient(221deg, #3273F6 0%, #80AAFF 100%);
  }
}
.divthree{
  justify-content: space-between;
   align-items: center;
   width: 1400px;
   margin: 0 auto;
   .flex1{
    flex: 1;
   }
  .thul{
    flex-wrap: wrap;
    font-size: 16px;
    color: #000000;
    padding-top: 33px;
    .thli{
      margin-bottom: 42px;
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      // margin-right: 40px;
    }
    .thli:nth-child(2n+1){
      width: 40%;
    }
    .thli:nth-child(2n){
      width: 60%;
    }
  }
  .threel{
    margin-right: 50px;
    // width: 1400px;
    margin-left: 70px;
    .border-b{
      height: 1px;
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, #CCDDFF 71%);
    }
    .morebtn{
      width: 178px;
      height: 68px;
      background: #3273F6;
      box-shadow: 0px 6px 12px 0px rgba(50,115,246,0.3);
      border-radius: 40px;
      text-align: center;
      color: #ffffff;
      line-height: 68px;
      margin-top: 64px;
      cursor: pointer;
    }
    .threeh2{
      height: 45px;
      font-size: 32px;
      font-weight: bold;
      color: #000000;
      line-height: 38px;
      margin-bottom: 20px;
    }
    .threeh3{
      font-size: 20px;
      font-weight: 400;
      color: #69728C;
      line-height: 23px;
    }
    .morebtn{
      font-size: 24px;
    }
  }
  .threer{
    display: block;
    width: 580px;
    height: 600px;
  }
}
.width103{
  background: url('https://qxqy-online.sdhckjvip.com/official/index/home_technology_bg@2x.png') no-repeat;
  background-size: 100% 100%;
  clear: both;
  .swiper-container .swiper-wrapper{
  -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
   margin: 0;
}
}
.swiper_pagination {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  display: flex;
}
.swiper_pagination >div {
  position: relative;
  width: 40px;
  margin-left: 8px;
  cursor: pointer;
}
.swiper_pagination >div:nth-child(1) {
  margin-left: 0;
}
.swiper_pagination >div div {
  height: 5px;
  border-radius: 2.5px;
}
.swiper_pagination >div div:nth-child(1) {
  position: absolute;
  background: #fff;
}
.swiper_pagination >div div:nth-child(2) {
  width: 40px;
  background: rgba(255, 255, 255, 0.50);
}
.active_swiper div:nth-child(1) {
  animation: silder 3s linear;
}
@keyframes silder {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.mc_swiper_active .blue-bottom {
  width: 100% !important;
}
.mc_swiper_active .title {
  color: #005CFF !important;
}
.mc_swiper_active .blue-top {
  width: 0 !important;
}
.my_swiper_img {
  width: 100%;
  height: 84px;
  object-fit: cover;
}
.my_swiper_img_to {
  transform: translateX(100px);
}
.scroll_box {
  width: 100%;
  overflow: hidden;
}
.bg_img,
.bg_img_to {
  width: 2000%;
  height: 100px;
  background-size: contain;
  background-repeat: repeat-x;
}
.bg_img {
  background-image: url('https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_1.png');
  animation: scroll 2000s linear infinite;
   animation-direction: alternate; /* 交替反转动画 */
}
.bg_img_to {
  background-image: url('https://qxqy-online.sdhckjvip.com/official/scroll/scroll_bar_2.png');
  animation: scroll 2000s linear 1s infinite;
   animation-direction: alternate; /* 交替反转动画 */
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 1200px) {
  //  .enterprise-div .swiper-slide .enterprise_card .content{
  //     font-size: 12px;
  //     line-height: 30px;
  //     transform:scale(0.5);
  //     transform-origin: left;
  //   }
  .towtitle{
    transform:scale(1.8);
    transform-origin: center;
  }
  .tabthree{
  // width: 90%;
      transform:scale(1.8);
      transform-origin: center;
  }
  .enterprise-div{
    transform: scale(1.2);
    transform-origin: center;
  }
  .divthree{
    margin-top: 60px;
    width: 90%;
    .thul{
      font-size:32px;
    }
    .threel{
      .threeh2{
        height:90px;
        font-size: 50px;
      
      }
      .threeh3{
        font-size: 40px;
        line-height: 46px;
      }
    }
    
  } 
  .morebtn{
    transform:scale(1.5);
    transform-origin: center;
  }
  .my_swiper_img{
    height: 184px;
  }
  .bg_img,
  .bg_img_to{
      height: 200px !important;
    }
  // .enterprise-div .swiper-slide .enterprise_card .content{
  //   font-size: 24px;
  // }
  .swiper-container{
    height: 120%;
  }
  // .enterprise-div{
		  
  //   }
    // .swiper-container{
    //   overflow-x:hidden ;
    //   overflow-y: auto;
    // }
   .enterprise-div .swiper-slide .enterprise_card .title{
     font-size: 40px;
      line-height: 50px;
  }
  .enterprise-div .swiper-slide .enterprise_card .content{
      font-size: 30px;
      position: relative;
      z-index: 200;
    }
  
}
@media screen and (max-width: 800px){
  .enterprise-div .swiper-slide .enterprise_card .title{
     font-size: 40px;
      
  }
  .enterprise-div .swiper-slide .enterprise_card .content{
      font-size: 30px;
  
    }
}
</style>